<template>
  <div class="container post">
    <div class="row">
      <div
          class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 postContainer"
      >
        <a
            class="btn btn-primary mb-2 text-white"
            v-if="$route.path.includes('/post-preview/')"
            @click="
            () => {
              $router.history.go(-1);
            }
          "
        ><i class="fa fa-chevron-left"></i> Back</a
        >

        <article class="single-blog">
          <div v-if="post && user">
            <a
                class="backBtn d-none"
                @click="
                () => {
                  $router.history.go(-1);
                }
              "
            ><i class="fa fa-arrow-left"></i
            ></a>

            <div class="post-thumb" style="overflow: hidden;">
              <loading
                  :active.sync="showImageLoader"
                  :color="'#408521'"
                  :is-full-page="false"
              ></loading>
              <img
                  :title="
                  post.photosCaptions && post.photosCaptions[0]
                    ? post.photosCaptions[0]
                    : ''
                "
                  :src="post.photos[0]"
                  :alt="post.photosCaptions && post.photosCaptions[0]
                    ? post.photosCaptions[0]
                    : ''"
                  v-if="post.photos && post.photos.length == 1"
                  @click="index = 0"
                  @load="onImgLoad"
                  v-b-tooltip.hover.bottom
              />

              <div v-if="post.photos && post.photos.length > 1">
                <b-carousel
                    background="light"
                    id="carousel-1"
                    :interval="7000"
                    controls
                    indicators
                >
                  <div
                      v-for="(item, _index) in post.photos"
                      :key="'item_' + _index"
                      role="listitem"
                      class="carousel-item"
                  >
                    <img
                        :title="
                        post.photosCaptions && post.photosCaptions[_index]
                          ? post.photosCaptions[_index]
                          : ''
                      "
                        class="post-img img-responsive text-center"
                        :src="item"
                        :alt=" post.photosCaptions && post.photosCaptions[_index]
                          ? post.photosCaptions[_index]
                          : ''"
                        @click="index = _index"
                        @load="onImgLoad"
                        v-b-tooltip.hover.bottom
                    />
                  </div>
                </b-carousel>
              </div>
            </div>
            <div class="post-content">
              <div class="entry-header text-center">
                <div
                    class="postUserAvatarAndBadge text-uppercase text-left mb-0"
                >
                  <UserAvatarWidget
                      :user-id="post.createdBy"
                      :location="'Home'"
                  ></UserAvatarWidget>

                  <img
                      v-if="post.recordFromNationalRegistry"
                      :src="require('@/assets/images/iz-registra-zig.svg')"
                      alt="Iz registra nesnovne kulturne dediščine"
                      class="img-responsive stamp"
                      style="height: 80px; height: 80px; object-fit: cover; margin-top: -20px;"
                  />
                </div>

                <a
                    @click="
                    () => {
                      $store.dispatch('setHomeOffsetTop', 0).then(() => {
                        $router
                          .push({
                            name: 'HomeWithCategoryFilter',
                            params: {
                              category: $globalFunctions.getCategoryById(
                                post.categoryId
                              ).alias
                            }
                          })
                          .catch(() => {});
                      });
                    }
                  "
                    class="post-cat text-uppercase mb-1"
                >{{
                    $globalFunctions.getCategoryById(post.categoryId).en_name
                  }}</a
                >
                <small
                    class="post-subcat d-block mb-3"
                    v-if="post.subcategoryId"
                >{{
                    $globalFunctions.getSubcategoryById(post.subcategoryId).en_name
                  }}</small
                >
                <h2 class="text-uppercase">{{ post.en_title ? post.en_title : post.title }}</h2>
              </div>

              <div class="entry-content">
                <p style="font-size: 1.1em">{{ post.en_summary ? post.en_summary : post.summary }}</p>

                <h6
                    class="text-center my-4"
                    style="font-size: 0.5em; opacity: 0.2"
                >
                  <i class="fa fa-circle mx-1"></i
                  ><i class="fa fa-circle mx-1"></i
                ><i class="fa fa-circle mx-1"></i>
                </h6>

                <div v-if="post.videos.length > 0">
                  <div
                      class="mb-4 videoInPost"
                      v-for="(video, index) in post.videos"
                      :key="'video_' + index"
                  >
                    <video-player
                        class="video-player-box"
                        style="width: 100%; max-width: 700px;"
                        :ref="'video_player_' + index"
                        :id="'video_player_' + index"
                        :options="{
                        poster: thumbnails[index] ? thumbnails[index] : null,
                        height: '366',
                        autoplay: false,
                        crossOrigin: '*',
                        muted: false,
                        preload: 'metadata',
                        language: 'sl',
                        playbackRates: [0.7, 1.0, 1.5, 2.0],
                        sources: [{ type: 'video/mp4', src: video.url.replace('dl=0', 'raw=1') }]
                      }"
                        @ready="onPlayerReady"
                        @loadeddata="onPlayerDataLoaded"
                    >
                    </video-player>
                  </div>
                </div>

                <div v-if="post.recordings.length > 0">
                  <div
                      class="mb-4 videoInPost"
                      v-for="(recording, index) in post.recordings"
                      :key="'recording_' + index"
                  >
                    <mini-audio
                        :audio-source="recording"
                        :html5="true"
                    ></mini-audio>
                  </div>
                </div>

                <div class="post-content-container" v-html="post.en_content ? post.en_content : post.content"></div>

                <likes-system :post="post" class="mt-1"></likes-system>

                <div v-if="post.connectedPosts">

                  <h6
                      class="text-center my-4"
                      style="font-size: 0.5em; opacity: 0.2"
                  >
                    <i class="fa fa-circle mx-1"></i
                    ><i class="fa fa-circle mx-1"></i
                  ><i class="fa fa-circle mx-1"></i>
                  </h6>

                  <div class="my-2" v-if="post.connectedPosts && post.connectedPosts.length > 0">
                    <small class="post-subcat d-block mb-1">Connected records</small>
                    <ul>
                      <li v-for="connectedPost in post.connectedPosts" :key="'connectedPost_' + connectedPost" style="list-style: square">
                        <ConnectedPost :postId="connectedPost" :key="'connectedPostConnected_' + connectedPost"></ConnectedPost>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="post-meta">
                <ul
                    class="pull-left list-inline author-meta text-left mb-0 pb-0"
                >
                  <li class="list-inline-item font-normal pb-2">
                    <a
                        class="regionLink"
                        @click="
                        () => {
                          $store.dispatch('setHomeOffsetTop', 0).then(() => {
                            $router
                              .push({
                                name: 'HomeWithRegionFilter',
                                params: {
                                  region: post.region
                                }
                              })
                              .catch(() => {});
                          });
                        }
                      "
                    >

                      {{ $globalFunctions.getTranslatedRegion(post.region).toUpperCase() }}
                    </a>
                  </li>
                  <li
                      class="date list-inline-item float-right"
                      v-if="!$route.path.includes('/post-preview/')"
                  >
                    {{
                      moment.unix(post.confirmedAt.seconds).format("D. M. YYYY")
                    }}
                  </li>
                </ul>
                <ul
                    class="pull-right list-inline social-share"
                    v-if="routeName === 'Post'"
                >
                  <li class="list-inline-item">
                    <vue-goodshare-facebook
                        :page_url="getFullURL"
                        has_icon
                        class="text-white rounded-circle"
                        style="width: 38px; height: 38px; padding-left: 8px"
                    >
                    </vue-goodshare-facebook>
                  </li>
                  <li class="list-inline-item">
                    <vue-goodshare-twitter
                        :page_url="getFullURL"
                        has_icon
                        class="text-white rounded-circle"
                        style="width: 38px; height: 38px; padding-left: 8px"
                    >
                    </vue-goodshare-twitter>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </article>
      </div>
      <div class="col-sm-4 d-none d-lg-block">
        <Sidebar></Sidebar>
      </div>
    </div>

    <div v-if="post && user && post.photos && post.photos.length > 0">
      <CoolLightBox
          :items="getGalleryItems"
          :index="index"
          @close="index = null"
      >
      </CoolLightBox>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Loading from "vue-loading-overlay";
import Sidebar from "@/components/EN_Sidebar";
import _ from "lodash";
import {db} from "@/main";
import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue";
import VueGoodshareTwitter from "vue-goodshare/src/providers/Twitter.vue";
import LikesSystem from "@/components/LikesSystem";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import UserAvatarWidget from "@/components/UserAvatarWidget";
import ConnectedPost from "@/components/ConnectedPost";

export default {
  name: "Post",
  components: {
    Sidebar,
    Loading,
    // Media,
    VueGoodshareFacebook,
    VueGoodshareTwitter,
    LikesSystem,
    CoolLightBox,
    UserAvatarWidget,
    ConnectedPost
  },
  data() {
    return {
      postId: this.$route.params.postId,
      routeName: this.$route.name, // this resource is shown via multiple routes!!!
      post: {
        categoryId: null,
        confirmed: null,
        confirmedAt: null,
        confirmedBy: null,
        content: null,
        createdAt: null,
        createdBy: null,
        id: null,
        informant: null,
        modifiedAt: null,
        photos: null,
        recordings: null,
        region: null,
        reviewersComment: null,
        subcategoryId: null,
        submitted: null,
        submittedAt: null,
        summary: null,
        tags: null,
        title: null,
        videos: null,
        likes: null
      },
      heroPost: null,
      config: null,
      categories: null,
      subcategories: null,
      isLoading: false,
      user: null,
      options: {},
      isLiked: false,

      thumbnails: [],
      index: null,
      showImageLoader: true,
    };
  },
  metaInfo() {
    return {
      title: `${this.post.title}`,
      meta: [
        {name: "description", content: this.post.summary},
        {property: "og:type", content: "website", vmid: "og:type"},
        {
          property: "og:image",
          content: this.post.photos ? this.post.photos[0] : null,
          vmid: "og:image"
        },
        {property: "og:title", content: this.post.title, vmid: "og:title"},
        {
          property: "og:description",
          content: this.post.summary,
          vmid: "og:description"
        },
        {property: "og:url", content: `${this.getFullURL}`, vmid: "og:url"}
      ]
    };
  },
  computed: {
    getFullURL() {
      return (
          "https://zapisi-spomina.dobra-pot.si/static.php?zapis=" +
          this.$route.params.postId +
          "&v=" +
          this.moment().valueOf()
      );
    },
    getGalleryItems() {
      const _this = this;

      let galleryItems = [];

      _.each(_this.post.photos, function (val, idx) {
        galleryItems.push({
          title:
              _this.post.photosCaptions && _this.post.photosCaptions[idx]
                  ? _this.post.photosCaptions[idx]
                  : "",
          src: _this.$globalFunctions.getLargeImage(val)
        });
      });

      return galleryItems;
    }
  },
  destroyed() {
    this.toggleBodyClass("removeClass", "postDetails");
  },
  beforeCreate() {
    const selectedLang = localStorage.getItem('zapisi_spomina_language');
    if(selectedLang !== null) {
      if(selectedLang === 'EN' && !this.$route.fullPath.includes('/en')) {
        this.$router.push({name: 'EN_' + this.$route.name}, this.$route.params);
      } else if(selectedLang === 'SI' && this.$route.fullPath.includes('/en')) {
        this.$router.push({name: this.$route.name.split('EN_')[1]}, this.$route.params);
      }
    }
  },
  mounted() {
    this.toggleBodyClass("addClass", "postDetails");

    // this resource is shown via multiple routes!!!
    console.log("$route", this.$route);

    const _this = this;
    let p1 = _this.$store.dispatch("setConfig");
    let p2 = _this.$store.dispatch("setCategories");
    let p3 = _this.$store.dispatch("setUsers");

    Promise.all([p1, p2, p3]).then(() => {
      _this.config = _this.$store.getters.getConfig;
      _this.categories = _this.$store.getters.getCategories;
      _this.subcategories = _this.$store.getters.getSubcategories;
      _this.users = _this.$store.getters.getUsers;

      _this.retrievePost();
    });
  },
  methods: {
    onImgLoad() {
      const _this = this;
      _this.showImageLoader = false;
    },
    async retrievePost() {
      const _this = this;

      _this.isLoading = true;

      _this.post = db
          .collection("posts")
          .doc(_this.postId)
          .get()
          .then(function (querySnapshot) {
            _this.post = querySnapshot.data();
            console.log("post data:", _this.post);

            _this.photoPreviews = _this.post.photos;
            _this.user = _this.$globalFunctions.getUserById(_this.post.createdBy);

            _this.post.likes = _this.post.likes ? _this.post.likes : [];

            if (_this.$store.getters.getUser) {
              _this.isLiked = _.filter(_this.post.likes, {
                userId: _this.$store.getters.getUser.id
              })[0]
                  ? true
                  : false;
            } else {
              _this.isLiked = false;
            }

            _.each(_this.post.videos, async function (val, _index) {
              let fileName = "/" + /[^/]*$/.exec(val.url)[0];
              fileName = fileName.split("?")[0];
              fileName = decodeURI(fileName);

              let res = await _this.$globalFunctions.getDropboxThumbnail(
                  fileName
              );

              _this.thumbnails[_index] = res;
              _this.$forceUpdate();
            });

            _this.isLoading = false;
          });
    },
    onPlayerReady(e) {
      // const _this = this;
      console.log("onPlayerReady", e);
    },
    onPlayerDataLoaded(e) {
      console.log("onPlayerDataLoaded", e);
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    }
  }
};
</script>
