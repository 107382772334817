var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container post"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 postContainer"},[(_vm.$route.path.includes('/post-preview/'))?_c('a',{staticClass:"btn btn-primary mb-2 text-white",on:{"click":function () {
            _vm.$router.history.go(-1);
          }}},[_c('i',{staticClass:"fa fa-chevron-left"}),_vm._v(" Back")]):_vm._e(),_c('article',{staticClass:"single-blog"},[(_vm.post && _vm.user)?_c('div',[_c('a',{staticClass:"backBtn d-none",on:{"click":function () {
                _vm.$router.history.go(-1);
              }}},[_c('i',{staticClass:"fa fa-arrow-left"})]),_c('div',{staticClass:"post-thumb",staticStyle:{"overflow":"hidden"}},[_c('loading',{attrs:{"active":_vm.showImageLoader,"color":'#408521',"is-full-page":false},on:{"update:active":function($event){_vm.showImageLoader=$event}}}),(_vm.post.photos && _vm.post.photos.length == 1)?_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],attrs:{"title":_vm.post.photosCaptions && _vm.post.photosCaptions[0]
                  ? _vm.post.photosCaptions[0]
                  : '',"src":_vm.post.photos[0],"alt":_vm.post.photosCaptions && _vm.post.photosCaptions[0]
                  ? _vm.post.photosCaptions[0]
                  : ''},on:{"click":function($event){_vm.index = 0},"load":_vm.onImgLoad}}):_vm._e(),(_vm.post.photos && _vm.post.photos.length > 1)?_c('div',[_c('b-carousel',{attrs:{"background":"light","id":"carousel-1","interval":7000,"controls":"","indicators":""}},_vm._l((_vm.post.photos),function(item,_index){return _c('div',{key:'item_' + _index,staticClass:"carousel-item",attrs:{"role":"listitem"}},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"post-img img-responsive text-center",attrs:{"title":_vm.post.photosCaptions && _vm.post.photosCaptions[_index]
                        ? _vm.post.photosCaptions[_index]
                        : '',"src":item,"alt":_vm.post.photosCaptions && _vm.post.photosCaptions[_index]
                        ? _vm.post.photosCaptions[_index]
                        : ''},on:{"click":function($event){_vm.index = _index},"load":_vm.onImgLoad}})])}),0)],1):_vm._e()],1),_c('div',{staticClass:"post-content"},[_c('div',{staticClass:"entry-header text-center"},[_c('div',{staticClass:"postUserAvatarAndBadge text-uppercase text-left mb-0"},[_c('UserAvatarWidget',{attrs:{"user-id":_vm.post.createdBy,"location":'Home'}}),(_vm.post.recordFromNationalRegistry)?_c('img',{staticClass:"img-responsive stamp",staticStyle:{"height":"80px","object-fit":"cover","margin-top":"-20px"},attrs:{"src":require('@/assets/images/iz-registra-zig.svg'),"alt":"Iz registra nesnovne kulturne dediščine"}}):_vm._e()],1),_c('a',{staticClass:"post-cat text-uppercase mb-1",on:{"click":function () {
                    _vm.$store.dispatch('setHomeOffsetTop', 0).then(function () {
                      _vm.$router
                        .push({
                          name: 'HomeWithCategoryFilter',
                          params: {
                            category: _vm.$globalFunctions.getCategoryById(
                              _vm.post.categoryId
                            ).alias
                          }
                        })
                        .catch(function () {});
                    });
                  }}},[_vm._v(_vm._s(_vm.$globalFunctions.getCategoryById(_vm.post.categoryId).en_name))]),(_vm.post.subcategoryId)?_c('small',{staticClass:"post-subcat d-block mb-3"},[_vm._v(_vm._s(_vm.$globalFunctions.getSubcategoryById(_vm.post.subcategoryId).en_name))]):_vm._e(),_c('h2',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.post.en_title ? _vm.post.en_title : _vm.post.title))])]),_c('div',{staticClass:"entry-content"},[_c('p',{staticStyle:{"font-size":"1.1em"}},[_vm._v(_vm._s(_vm.post.en_summary ? _vm.post.en_summary : _vm.post.summary))]),_vm._m(0),(_vm.post.videos.length > 0)?_c('div',_vm._l((_vm.post.videos),function(video,index){return _c('div',{key:'video_' + index,staticClass:"mb-4 videoInPost"},[_c('video-player',{ref:'video_player_' + index,refInFor:true,staticClass:"video-player-box",staticStyle:{"width":"100%","max-width":"700px"},attrs:{"id":'video_player_' + index,"options":{
                      poster: _vm.thumbnails[index] ? _vm.thumbnails[index] : null,
                      height: '366',
                      autoplay: false,
                      crossOrigin: '*',
                      muted: false,
                      preload: 'metadata',
                      language: 'sl',
                      playbackRates: [0.7, 1.0, 1.5, 2.0],
                      sources: [{ type: 'video/mp4', src: video.url.replace('dl=0', 'raw=1') }]
                    }},on:{"ready":_vm.onPlayerReady,"loadeddata":_vm.onPlayerDataLoaded}})],1)}),0):_vm._e(),(_vm.post.recordings.length > 0)?_c('div',_vm._l((_vm.post.recordings),function(recording,index){return _c('div',{key:'recording_' + index,staticClass:"mb-4 videoInPost"},[_c('mini-audio',{attrs:{"audio-source":recording,"html5":true}})],1)}),0):_vm._e(),_c('div',{staticClass:"post-content-container",domProps:{"innerHTML":_vm._s(_vm.post.en_content ? _vm.post.en_content : _vm.post.content)}}),_c('likes-system',{staticClass:"mt-1",attrs:{"post":_vm.post}}),(_vm.post.connectedPosts)?_c('div',[_vm._m(1),(_vm.post.connectedPosts && _vm.post.connectedPosts.length > 0)?_c('div',{staticClass:"my-2"},[_c('small',{staticClass:"post-subcat d-block mb-1"},[_vm._v("Connected records")]),_c('ul',_vm._l((_vm.post.connectedPosts),function(connectedPost){return _c('li',{key:'connectedPost_' + connectedPost,staticStyle:{"list-style":"square"}},[_c('ConnectedPost',{key:'connectedPostConnected_' + connectedPost,attrs:{"postId":connectedPost}})],1)}),0)]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"post-meta"},[_c('ul',{staticClass:"pull-left list-inline author-meta text-left mb-0 pb-0"},[_c('li',{staticClass:"list-inline-item font-normal pb-2"},[_c('a',{staticClass:"regionLink",on:{"click":function () {
                        _vm.$store.dispatch('setHomeOffsetTop', 0).then(function () {
                          _vm.$router
                            .push({
                              name: 'HomeWithRegionFilter',
                              params: {
                                region: _vm.post.region
                              }
                            })
                            .catch(function () {});
                        });
                      }}},[_vm._v(" "+_vm._s(_vm.$globalFunctions.getTranslatedRegion(_vm.post.region).toUpperCase())+" ")])]),(!_vm.$route.path.includes('/post-preview/'))?_c('li',{staticClass:"date list-inline-item float-right"},[_vm._v(" "+_vm._s(_vm.moment.unix(_vm.post.confirmedAt.seconds).format("D. M. YYYY"))+" ")]):_vm._e()]),(_vm.routeName === 'Post')?_c('ul',{staticClass:"pull-right list-inline social-share"},[_c('li',{staticClass:"list-inline-item"},[_c('vue-goodshare-facebook',{staticClass:"text-white rounded-circle",staticStyle:{"width":"38px","height":"38px","padding-left":"8px"},attrs:{"page_url":_vm.getFullURL,"has_icon":""}})],1),_c('li',{staticClass:"list-inline-item"},[_c('vue-goodshare-twitter',{staticClass:"text-white rounded-circle",staticStyle:{"width":"38px","height":"38px","padding-left":"8px"},attrs:{"page_url":_vm.getFullURL,"has_icon":""}})],1)]):_vm._e()])])]):_vm._e()])]),_c('div',{staticClass:"col-sm-4 d-none d-lg-block"},[_c('Sidebar')],1)]),(_vm.post && _vm.user && _vm.post.photos && _vm.post.photos.length > 0)?_c('div',[_c('CoolLightBox',{attrs:{"items":_vm.getGalleryItems,"index":_vm.index},on:{"close":function($event){_vm.index = null}}})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:"text-center my-4",staticStyle:{"font-size":"0.5em","opacity":"0.2"}},[_c('i',{staticClass:"fa fa-circle mx-1"}),_c('i',{staticClass:"fa fa-circle mx-1"}),_c('i',{staticClass:"fa fa-circle mx-1"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:"text-center my-4",staticStyle:{"font-size":"0.5em","opacity":"0.2"}},[_c('i',{staticClass:"fa fa-circle mx-1"}),_c('i',{staticClass:"fa fa-circle mx-1"}),_c('i',{staticClass:"fa fa-circle mx-1"})])}]

export { render, staticRenderFns }